<app-modal-generic [title]="'global.card-item.orariAggiornamento' | translate">

    <ng-container body>
        <form [formGroup]="form" (submit)="handleSubmit()">
            <div class="row">
                <app-input-generic only="number" maxLength="512" class="col-sm-12" label="global.card-item.oreLavorate"
                    nameInput="oreLavorate" maxLength="512" [formGroup]="form"
                    [showError]="hasError"></app-input-generic>
                <!-- <app-input-generic maxLength="512" class="col-sm-12" label="global.card-item.prossimaManutenzione"
                    nameInput="prossimaManutenzione" maxLength="512" [formGroup]="form"
                    [showError]="hasError"></app-input-generic> -->
            </div>
        </form>
    </ng-container>
    <ng-container footer>
        <div class="row">
            <div class="col-md-12 pr-0">
                <button [disabled]="isSubmitted" (click)="handleCancel()" type="button"
                    class="btn bg-gradient-secondary">{{'global.utils.annulla' | translate}}</button>
                <button (click)="handleSubmit()" type="button" class="btn bg-gradient-success ml-2">
                    {{!isSubmitted ?('global.utils.salva' | translate) : ''}}
                    <ng-container *ngIf="isSubmitted">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        {{'global.utils.caricamento' | translate}}...
                    </ng-container>
                </button>
            </div>
        </div>
    </ng-container>

</app-modal-generic>
export const environment = {
  production: true,
  captcha: '6LeCu9spAAAAAL4jPtUkGc2E2PxjL5UvNALKxcC3',
  mock: true,
  apiUrl: 'https://api.wmt.docutechsoftware.it/api',
  newApiUrl: 'https://api.wmt.docutechsoftware.it/api',
  cookieDomain: 'localhost:7017',
  local: {
    apiUrl: 'https://localhost:8080/api',
    authenticate: 'https://localhost:8081/api'
  },
  server: {
    //! auth client
    // apiUrl: 'https://api.masegenerators.ncweb.it/api',
    // authenticate: 'https://auth.masegenerators.ncweb.it/api'
    //! digi2
    apiUrl: 'https://api.wmt.docutechsoftware.it/api',
    authenticate: 'https://auth.wmt.docutechsoftware.it/api'
  },
};
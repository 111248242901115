import { HelperSelect } from '@/_application/helpers/helper-select';
import { OptionsSelect } from '@/_application/interfaces/select';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthenticationService } from '@modules/authentication/services/authentication.service';
import { PublicationService } from '@modules/publications/services/publication.service';
import { tap } from 'rxjs';

export const typeSerialNumber: OptionsSelect[] = [
  {
    label: 'Cliente',
    value: 10
  },
  {
    label: 'Armatore',
    value: 5
  },
  {
    label: 'Distributore',
    value: 6
  },
  {
    label: 'Officina',
    value: 4
  },
  {
    label: 'Installer',
    value: 7
  }
]
@Component({
  selector: 'app-select-type-serial-number',
  templateUrl: './select-type-serial-number.component.html',
  styleUrls: ['./select-type-serial-number.component.scss']
})
export class SelectTypeSerialNumberComponent extends HelperSelect {
  @Input() showError: boolean = false;
  @Input() readonly: boolean = false;
  @Input() control: any;
  @Input() label: string = '';
  @Output() optionsSelect = new EventEmitter<OptionsSelect[]>()
  public list: OptionsSelect[]
  constructor(private authSvc: AuthenticationService,
    private publicationSvc: PublicationService,
    private _cd: ChangeDetectorRef) {
    super();

  }
  protected handleData(search: string, query?: any): void {
    const { userGuid, reseller, role  } = this.authSvc.currentUserValue.current
    if (!role.gestisciTagliandi) {
      query = {
        userGuid: !reseller ? userGuid : null,
        PubTipo:2,
        MatCodice: search
      };
    } else {
      query = {
        PubTipo:2,
        MatCodice: search
      };
    }
    
    this.publicationSvc
      .getAllMatricoleAsyncWhitQuery({}, !reseller && query)
      .pipe(
        tap(data => {

          console.log('TAGLIANDI DATA...')
          console.log(data)
          
          const rows: OptionsSelect[] = data.data
            .flatMap(item =>
            ({
                  value: `${item.pubID}-${item.matCode}`,
                  label: item.matCode
                })
            );
          // this.list = rows;
          this.rows = rows;
          this.optionsSelect.emit(rows);
          this._cd.detectChanges();
        })
      )
      .subscribe();
  }

}

<div class="form-group relative">
  <label *ngIf="label.length > 0">{{label}}</label>
  <ng-select class="typehead" [virtualScroll]="true" [items]="options" bindLabel="label" bindValue="value"
    [ngModel]="code?.value || null" [loading]="loading" [loadingText]="(selEntity.selectLoading | translate) + '...'"
    [notFoundText]="pubEntity.errorNoArticlesFound | translate" [placeholder]="placeholder | translate" [disabled]="disabled || required"
    (change)="selectEvent($event)" (clear)="clear()" (search)="onChangeSearch($event)" [readonly]="readonly">
  </ng-select>
  <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
      validation: 'required',
      message: pubEntity.errorFieldMandatory | translate,
      control: control
    }"></ng-container>

  <ng-template #formError let-control="control" let-message="message" let-validation="validation">
    <ng-container *ngIf="showError && control && control.hasError(validation)">
      <div class="wrapper">
        <div class="text-danger">
          {{ message }}
        </div>
      </div>
    </ng-container>
  </ng-template>

  <ng-template #itemTemplate let-item>
    <a [innerHTML]="item.label"></a>
  </ng-template>

  <ng-template #notFoundTemplate let-notFound>
    <div [innerHTML]="notFound"></div>
  </ng-template>
</div>

import { HttpContextToken, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@modules/authentication/services/authentication.service';
import { Observable, catchError, throwError } from 'rxjs';
import { Sweet, AlertType, AlertTypeIcons, authLocalKey } from '../config/config';
import { getSweetAlert } from '../sweet-alert/sweet.alert';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

export const BYPASS_TOKEN = new HttpContextToken(() => false);

@Injectable({
  providedIn: 'root'
})
export class JwtInterceptorService implements HttpInterceptor {

  sessionExpired = ''
  forbidden = ''
  serverError = ''
  serverOffline = ''
  error = ''
  nonPublish = ''
  nonCataloghi = ''
  nonValid = ''
  managerToUser = ''
  notAllowed = ''
  notAllowedAddTagliandi = ''

  constructor(
    private _authenticationService: AuthenticationService,
    private router: Router,
    private readonly injector: Injector,
    private readonly translate: TranslateService
  ) {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.error = event.translations['error']['form']['error']
      this.sessionExpired = event.translations['error']['form']['errorSessionExpired']
      this.forbidden = event.translations['error']['form']['errorForbidden']
      this.serverError = event.translations['error']['form']['errorServerError']
      this.serverOffline = event.translations['error']['form']['errorServerOffline']
      this.nonPublish = event.translations['matricole']['exc']['serialNumberNoDocCataloghiAv']
      this.nonCataloghi = event.translations['filiali']['exc']['branchNoExists']
      this.nonValid = event.translations['error']['form']['errorLinkInvalid']
      this.managerToUser = event.translations['utenti']['exc']['managerToUser']
      this.notAllowed = event.translations['global']['utils']['notAllowed']
      this.notAllowedAddTagliandi = event.translations['global']['utils']['notAllowedAddTagliandi']
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


    if (req.context.get(BYPASS_TOKEN)) {
      return next.handle(req);
    }

    const user = this._authenticationService.currentUserValue;

    if (localStorage.getItem(authLocalKey)) {
      req = req.clone({
        setHeaders: {
          Authorization: 'Bearer ' + localStorage.getItem(authLocalKey),
        },
      });
    }const errorDescriptions = [
      'clienti.exc.recordUsed',
      'distributori.exc.recordUsed',
      'armatori.exc.recordUsed',
      'installatori.exc.recordUsed',
      'officine-autorizzate.exc.recordUsed',
      'destinatari.exc.recordUsed',
      'filiali.exc.recordUsed',
      'agenti.exc.recordUsed'
    ];

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        const translateService = this.injector.get(TranslateService)
        if (error.status === 401 && this._authenticationService.isLogged()) {
          this._authenticationService.deaAthorizeToken();
          this.router.navigate(['/auth/login'], {
            queryParams: {
              err: 'authentication_expired',
            }
          }).then(() => {
            let message = this.sessionExpired;
            let customError: Sweet = { title: this.error, html: message }
            getSweetAlert(AlertType.Basic, AlertTypeIcons.Error, customError)();
            this._authenticationService.changeStatus('verifed')
          });

        } else if (error.status === 403) {
          let message = this.forbidden;
          let customError: Sweet = { title: this.error, html: message }
          getSweetAlert(AlertType.Basic, AlertTypeIcons.Error, customError)();
        } else if (error.status === 400) {
          if (error.error.description === 'matricole.exc.serialNumberNoCataloghi') {
            let message = this.nonPublish;
            let customError: Sweet = { title: "Info", html: message }
            getSweetAlert(AlertType.Basic, AlertTypeIcons.Info, customError)();
          } else if (error.error.description === 'filiali.exc.branchNoExists') {
            const message = this.nonCataloghi;
            const customError: Sweet = { title: 'Errore', html: message }
            console.error(customError)
          } else if (error.error.description === 'error.form.errorLinkInvalid') {
            const message = this.nonValid;
            const customError: Sweet = { title: 'Info', html: message }
            getSweetAlert(AlertType.Basic, AlertTypeIcons.Info, customError)();
          }else if (error.error.description === 'utenti.exc.managerToUser') {
            const message = this.managerToUser;
            const customError: Sweet = { title: 'Info', html: message }
            getSweetAlert(AlertType.Basic, AlertTypeIcons.Info, customError)();
          }else if (error.error.description === 'matricole.exc.serialNumberExists') {
            const message = translateService.instant( error.error.description);
            const customError: Sweet = { title: 'Info', html: message }
            getSweetAlert(AlertType.Basic, AlertTypeIcons.Info, customError)();
          }else if (error.error.description === 'global.utils.notAllowed') {
            const message = this.notAllowed;
            const customError: Sweet = { title: 'Info', html: message }
            getSweetAlert(AlertType.Basic, AlertTypeIcons.Info, customError)();
            this.router.navigate(['/']);
          }else if (error.error.description === 'global.utils.notAllowedAddTagliandi') {
            const message = this.notAllowedAddTagliandi;
            const customError: Sweet = { title: 'Info', html: message }
            getSweetAlert(AlertType.Basic, AlertTypeIcons.Info, customError)();
            this.router.navigate(['/']);
          }else if (errorDescriptions.includes(error.error.description)) {
            const message =translateService.instant( error.error.description);
            const customError: Sweet = { title: 'Info', html: message }
            getSweetAlert(AlertType.Basic, AlertTypeIcons.Info, customError)();
          }else {
            let message: Sweet = { title: this.error, html: translateService.instant(error.error.description) }
            getSweetAlert(AlertType.Basic, AlertTypeIcons.Error, message)();
          }
        } else if (error.status === 500) {
          let message = this.serverError;
          let customError: Sweet = { title: this.error, html: message }
          getSweetAlert(AlertType.Basic, AlertTypeIcons.Error, customError)();
        } else if (error.status === 0) {
          const message = this.serverOffline;
          const customError: Sweet = { title: 'Faulty server', html: message }
          console.error(customError)
        }
        return throwError(() => error);
      })
    );
  }
}

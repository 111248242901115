import { errorEntity } from '@/_application/config/genericEntity';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

export type onlyCharacter = '' | 'number'
@Component({
  selector: 'app-input-generic',
  templateUrl: './input-generic.component.html',
  styleUrls: ['./input-generic.component.scss']
})
export class InputGenericComponent implements OnInit {
  @Input() label = '';
  @Input() nameInput = '';
  @Input() placeholder = '';
  @Input() showLabel = true;
  @Input() align = '';
  @Input() max = '';
  @Input() only: onlyCharacter = '';
  @Input() type = 'text';
  @Input() maxLength: string | null = null;
  @Input() minlength: string | null = null;
  @Input() formGroup!: FormGroup;
  @Input() showError: boolean = false;
  @Input() control: FormControl;
  @Input() read: boolean = false;
  @Input() matchNotPassword: boolean = false;
  pubEntity = errorEntity

  @Input() module = '';
  @Input() view = 'form';
  @Input() showButton: boolean = false;
  @Input() labelButton: string = '';
  textLabel = ""
  @Output() blurView = new EventEmitter()
  @Output() buttonClick = new EventEmitter();
  @Output() change = new EventEmitter<string>();
  isButtonDisabled: boolean = true;
  constructor() {

  }
  ngOnInit() {
    if(this.control) {
      this.control.valueChanges.subscribe(value => {
        this.checkInputValue();
      });
    }
  }

  checkInputValue() {
    const value = this.control.value;
    this.isButtonDisabled = !value || value.trim().length === 0;
  }
  methodWithBlur() {
    this.blurView.emit(this.formGroup.controls[this.nameInput].value);
  }
  isRequired(control: any) {
    return control.validator && control.validator({} as FormControl) && control.validator({} as FormControl).required;
  }
  validateFormat(event) {
    if (this.only === '') return
    let key: any;
    if (event.type === 'paste') {
      key = event.clipboardData.getData('text/plain');
    } else {
      key = event.keyCode;
      key = String.fromCharCode(key);
    }
    const regex = /[0-9]+$/;
    if (!regex.test(key)) {
      event.returnValue = false;
      if (event.preventDefault) {
        event.preventDefault();
      }
    }
  }
  clearSpacesStart(event) {
    event.target.value = event.target.value.trimStart();
    this.change.emit(event.target.value);
  }
  onButtonClick() {
    this.buttonClick.emit();
  }

}

import { ModalService } from '@/_application/services/modal.service';
import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import {TranslateModule} from '@ngx-translate/core';
import { ModalGenericModule } from './modal-generic/modal-generic.module';
import { NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'app-modal-generic',
  standalone: true,
  templateUrl: './modal-generic.component.html',
  styleUrls: ['./modal-generic.component.scss'],
  imports: [
    CommonModule,
    TranslateModule,
    ModalGenericModule,
  ],
  
})
export class ModalGenericComponent implements OnInit, OnDestroy {

  @Input() title: string = '';
  @ViewChild('modal') modal: ElementRef;
  @Output() scrollEnd = new EventEmitter<boolean>(false);
  
  subscribeModal = new Subscription();
  onShowModal: boolean = false;
  @Input() scroll = false
  constructor(
    public _modalSvc: ModalService,
    private router: Router
    ) { }
    
    ngOnInit(): void {
      this.subscribeModal = this._modalSvc.showModal$.subscribe((res) => {
      this.onShowModal = res;
    });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart && this.onShowModal) {
        this.closeModal();
      }
    });
  }
  
  @HostListener('document:click', ['$event'])
  onClick(event: Event): void {
    if (!this.modal.nativeElement.contains(event.target)) {
      if(document.getElementById('id-modal-overlay').classList.contains('display-block')) {
        //this.closeModal();
      }
    }
  }

  ngOnDestroy(): void {
    this.subscribeModal.unsubscribe();
  }

  closeModal() {
    this._modalSvc.setShowModal(false);
  }

  showModal() {
    this._modalSvc.setShowModal(true);
  }

  onScrollEnd(event: any) {
    const scrollTop = event.target.scrollTop;
    const scrollHeight = event.target.scrollHeight;
    const offsetHeight = event.target.offsetHeight;
    const bottom = scrollHeight - offsetHeight - scrollTop;
    const scroll = Number(bottom).toFixed(0);

    //@ts-ignore
    if (scroll <= 35) {
      this.scrollEnd.emit(true);
    }
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DefaultLangChangeEvent, LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-checkbox-generic',
  templateUrl: './checkbox-generic.component.html',
  styleUrls: ['./checkbox-generic.component.scss']
})
export class CheckboxGenericComponent implements OnInit {
  @Input() label = '';
  @Input() nameInput = '';
  @Input() placeholder = '';
  @Input() align = '';
  @Input() formGroup!: FormGroup;
  @Input() showError: boolean = false;
  @Input() horizontal: boolean = false;
  @Input() disabled: boolean = false;

  @Input() module = '';
  @Input() view = 'form';


  constructor(private readonly translate: TranslateService) {

  }
  ngOnInit(): void {
    /*this.translate.onDefaultLangChange.subscribe((event: DefaultLangChangeEvent) => {
      const menu = event.translations[this.module][this.view][this.nameInput]
      this.label = menu
    });

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      const menu = event.translations[this.module][this.view][this.nameInput]
      this.label = menu
    });*/
  }
}

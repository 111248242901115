<div id="id-modal-overlay" style="background-color: rgba(0, 0, 0, 0.5);" class="modal fade show"
    [class]="{'display-block': (_modalSvc.showModal$ | async), 'display-none': (_modalSvc.showModal$ | async) === false}"
    tabindex="-1" role="dialog" aria-modal="true" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" [ngClass]="{'modal-dialog-scrollable' :scroll}" role="document">
        <div class="modal-content" #modal>
            <div class="modal-header py-2">
                <h3 class="modal-title m-0 font-bold"><b>{{ title | translate }}</b></h3>
                <button (click)="closeModal()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" (scroll)="onScrollEnd($event)">
                <ng-content select="[body]"></ng-content>
            </div>
            <div class="modal-footer py-2">
                <ng-content select="[footer]"></ng-content>
            </div>
        </div>
    </div>
</div>
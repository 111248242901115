<div class="col-sm-4" *ngIf="user.current.role.canReport">
    <div class="dt-buttons btn-group flex-wrap">
        <button (click)="requestReport('excel')" class="btn btn-info buttons-excel buttons-html5"
            type="button"><span>Excel</span></button>
        <button (click)="requestReport('pdf')" class="btn btn-secondary buttons-pdf buttons-html5"
            type="button"><span>PDF</span>
        </button>

    </div>
</div>
<div class="col-sm-8">
    <div class="card-tools">
        <form (ngSubmit)="requestHttp()" [formGroup]="form">
            <div class="input-group input-group-md">
                <input type="text" name="table_search" class="form-control float-right" placeholder="{{'global.utils.ricerca' | translate}}"
                    formControlName="search">
                <div class="input-group-append">
                    <button type="submit" class="btn btn-default">
                        <i class="fas fa-search"></i>
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>

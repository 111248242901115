<div class="d-flex menu-header">
    <div class="d-flex" style="width: 90%;" (click)="fields != 'existsInGaranzie' && sort()">
        <span>{{ label | translate }}</span>
        <span class="mx-2">
            <i
                *ngIf="fields != 'existsInGaranzie'" [ngClass]="{'fa sort-icon': true, 'fa-chevron-up': column === fields, 'fa-chevron-down': column !== fields}"></i>
        </span>
    </div>
    <pf-dropdown #dropdownTable hide-arrow [openOnButtonClick]="false" *ngIf="filter && fields != 'existsInGaranzie'" >
        <div slot="button" class="mpppp">
            <span (click)="handleIconClick()" class="position-absolute px-1" style="right: -10px;">
                <i [ngClass]="{'text-gray': !active, 'text-dark': active}" class="fas fa-filter"></i>
            </span>
        </div>
        <div class="menu menu-custom" slot="menu">
            <input #filterInput type="text" [(ngModel)]="filterValue" (keyup.enter)="searchByFilterValue()" class="form-control"
                [placeholder]="(pubEntity.tableSearch | translate)+'...'" [autofocus]="true">
            <div class="wrapper-btn">
                <button type="button" (click)="searchByFilterValue()" class="btn btn-info mt-1">{{ pubEntity.tableFilter | translate }}</button>
                <button type="button" (click)="clearValue()" class="btn btn-secondary mt-1">{{ pubEntity.tableDelete | translate }}</button>
            </div>
        </div>
    </pf-dropdown>
</div>

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  private showModal = new BehaviorSubject<boolean>(false);
  showModal$ = this.showModal.asObservable();

  private current = new BehaviorSubject<boolean>(false);
  current$ = this.current.asObservable();
  constructor() { }

  setShowModal(value: boolean, current?:any) {
    this.showModal.next(value);
    this.current.next(current)
  }

  getShowModal() {
    return this.showModal.value;
  }

  get currentValue(){
    return this.current.value
  }

}

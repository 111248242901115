import { AlertType, AlertTypeIcons, ExportFormat } from "@/_application/config/config";
import { OptionsSelect, ValueNav } from "@/_application/interfaces/select";
import { SubMenu } from "@/_application/interfaces/wrapper";
import { ExportService } from "@/_application/services/export.service";
import { LoadingService } from "@/_application/services/loading.service";
import { WrapperService } from "@/_application/services/wrapper.service";
import { getSweetAlert } from "@/_application/sweet-alert/sweet.alert";
import { FormValidations } from "@/_application/validations/form.validations";
import { AfterViewInit, Component, OnDestroy, OnInit, inject } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { UserCurrent } from "@modules/authentication/interfaces/user";
import { AuthenticationService } from "@modules/authentication/services/authentication.service";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, Observable } from "rxjs";

@Component({
    template: '',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export abstract class FormGeneric implements OnInit, OnDestroy, AfterViewInit {
    public hasError: boolean;
    public currentEntity$ = new BehaviorSubject<string | undefined>(undefined);
    public initConfigExt: boolean = true
    public loadForm = false;
    public item: any;
    public entity: any;
    public queryParams: any;
    public isEdit: boolean = false;
    public overflow: boolean = false;
    public bodyOver: boolean = false;
    public formMaxWidth: boolean = false;
    public isSubmitted: boolean;
    public codice: number = 0;
    errorCannotRestore: string = ''
    public nav: ValueNav = {
        opt: ''
    };
    public subMenu: SubMenu[] = []

    public form!: FormGroup;

    public validations = new FormValidations()
    public user: UserCurrent | null = null
    open = true
    smsErrorTitle = ''

    constructor() {
        this.hasError = false;
        this.isSubmitted = false;
        this.user = this.userRole.currentUserValue
        this._wrapperSvc.setShowTitle(false);

        this.translate.get('error.form.errorAreYouSure').subscribe((translation: string) => {
            this.smsErrorTitle = translation;
        });

        this.translate.get('error.form.errorCannotRestore').subscribe((translation: string) => {
            this.errorCannotRestore = translation;
        });
    }
    ngAfterViewInit(): void {
        this.handleOverflow()
        if (!this.user.current.role.canWrite && this.open) {
            this.form.disable()
        }

        this._wrapperSvc.setShowTitle(false);
    }

    private readonly _routeParams: ActivatedRoute = inject(ActivatedRoute);
    public readonly _wrapperSvc: WrapperService = inject(WrapperService)
    public readonly _fb: FormBuilder = inject(FormBuilder);
    public readonly _router: Router = inject(Router);
    public readonly toastr: ToastrService = inject(ToastrService);
    private readonly userRole: AuthenticationService = inject(AuthenticationService);
    public readonly _exportSvc: ExportService = inject(ExportService)
    public _loadingSvc: LoadingService = inject(LoadingService)
    public translateService: TranslateService = inject(TranslateService)
    private readonly translate: TranslateService = inject(TranslateService)

    ngOnInit(): void {
        this.handleParams()
        this.validateEdit();
        this.initConfig();
        this.initForm().subscribe(x => {
            console.log(x)
            if (this.isEdit) this.getDataForEdit();
        });

        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.smsErrorTitle = event.translations['error']['form']['errorAreYouSure']
        });

        this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
            this.errorCannotRestore = event.translations['error']['form']['errorCannotRestore']
        });

        this._wrapperSvc.setShowTitle(false);
    }

    handleParams() {
        this._routeParams.queryParams.subscribe(elem => {
            this.queryParams = elem
        })
    }
    handleOverflow() {
        if (this.overflow) {
            const form = document.querySelector('.wrapper.wrapper-form')
            form.classList.add('not-scroll')
        }

        if (this.bodyOver) {
            const form = document.querySelector('html')
            form.classList.add('not-scroll')
        }

        if (this.formMaxWidth && this.isEdit) {
            const form = document.querySelector('.wrapper.wrapper-form')
            form.classList.add('not-scroll-full')
        }
    }

    ngOnDestroy(): void {
        if (this.initConfigExt) this._wrapperSvc.removeCurrent()

        if (this.bodyOver) {
            const form = document.querySelector('html')
            form.classList.remove('not-scroll')
        }

        if (this.formMaxWidth && this.isEdit) {
            const form = document.querySelector('.wrapper.wrapper-form')
            form.classList.remove('not-scroll-full')
        }

        this._wrapperSvc.setShowTitle(true);
    }
    private validateEdit() {
        this._routeParams.params.subscribe((result) => {
            const codice = result['id'];
            this.isEdit = !!codice;
            this.codice = codice;
            if (!this.isEdit) {
                this.loadForm = true;
            }
        });
    }

    private initConfig() {
        if (!this.initConfigExt) return
        this.currentEntity$.subscribe((currentValue) => {
            const message = this.isEdit ? (this.entity.edit + (currentValue ? ': ' + currentValue : '')) : this.entity.add
            this._wrapperSvc.addGeneralInfo({
                title: message,
                subMenu: [this.subMenu[0], { title: message, active: true }]
            })
        })
    };

    public handleSubmited() {
        if (!this.isValidForm()) return;
        this.hasError = false;
        const values = this.form.value;
        this.handleSubmit(values);
    }

    public handleOperationFinish(message: string, redirect = true, reset = true) {
        this.toastr.success(this.translateService.instant(message))
        if (redirect) this._router.navigate([this.entity.route], {
            queryParams: this.entity.params
        });
        this.isSubmitted = false;
        reset && this.form.reset(undefined)
    }

    public handleErrorConnectEmail(message: string, redirect = true, reset = true) {
        this.toastr.error(this.translateService.instant(message))
        if (redirect) this._router.navigate([this.entity.route], {
            queryParams: this.entity.params
        });
        this.isSubmitted = false;
        reset && this.form.reset(undefined)
    }

    private isValidForm() {
        if (!this.form.valid) {
            this.hasError = true;
            return false;
        }
        return true;
    }

    handleDelete() {
        const { titleDelete, htmlDelete } = this.entity;
        getSweetAlert(
            AlertType.Confirm,
            AlertTypeIcons.Question,
            {
                title: titleDelete || this.smsErrorTitle,
                html: htmlDelete || this.errorCannotRestore,
                btnCancel: this.translate.instant('global.utils.annulla'),
            },
            () => {
                this.deleted();
                //localStorage.setItem('deletedEntity', 'true');
            }
        );
    }

    onChange(value: any, key: string) {
        this.form.patchValue({
            [key]: value,
        });
    }

    handleEmpty(item: OptionsSelect | null) {
        if (!item) return null
        const empty = Object.entries(item).length === 2
        if (!empty) return null
        return item.value
    }

    handleDate() {
        const date = new Date().toISOString().split('T')[0]
        return date
    }

    handleSelectedNav(nav: ValueNav) {
        this.nav = nav
    }

    saveFile(data: any) {
        this._exportSvc.generatePdf(data, this.entity.fileName, ExportFormat.Pdf);
        this.toastr.success(this.entity.messageExport);
    }

    protected abstract initForm(): Observable<boolean | any>;
    protected abstract deleted(): void;
    protected abstract getDataForEdit(): void;
    protected abstract handleSubmit(formValues: any): void;

}
